import React from 'react';
import { ThreeDotsLoader as ThreeDotsLoaderComponent } from 'wix-ui-tpa/cssVars';

import { st, classes } from './ThreeDotsLoader.st.css';

const ThreeDotsLoader = () => {
  return (
    <div className={st(classes.loaderContainer)}>
      <ThreeDotsLoaderComponent />
    </div>
  );
};

export default ThreeDotsLoader;
