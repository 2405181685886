import React, { ReactElement } from 'react';
import { ThreeDotsLoader as ThreeDotsLoaderComponent } from 'wix-ui-tpa/cssVars';

import { st, classes } from './ItemList.st.css';

interface ListItemProps {
  id: string;
}

type ItemListProps<T> = {
  isMobile?: boolean;
  isLoading?: boolean;
  data: T[];
  renderItem: (data: T) => ReactElement;
};

type ItemListType = <T extends ListItemProps>(
  props: ItemListProps<T>,
) => ReactElement;

const ItemList: ItemListType = ({
  isMobile = false,
  isLoading,
  data,
  renderItem,
}) => {
  if (isLoading) {
    return (
      <div className={st(classes.listLoaderContainer)}>
        <ThreeDotsLoaderComponent />
      </div>
    );
  }

  return (
    <div className={st(classes.listContainer)}>
      {data.map((item) => {
        const ItemComponent = () => renderItem(item);

        return (
          <div
            key={item.id}
            className={st(classes.itemContainer, { mobile: isMobile })}
          >
            <ItemComponent />
          </div>
        );
      })}
    </div>
  );
};

export default ItemList;
